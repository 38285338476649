import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import Banner from "../components/Banner"
import Layout from "../components/layout"
import FindUs from "../components/company/FindUs"
import arrow from "../assets/images/icons/arrow.svg"
import backImg from "../assets/images/contact/contactSwirl.svg"
import { InlineWidget } from "react-calendly"
import SubscribeForm from "../components/form/subscribe-form"

const Contact = () => {
    return (
        <Layout pageName="contact">
            <Seo title="Contact" />
            <Banner width="fullwidth" pageName="contact">
                <img
                    src={backImg}
                    className="banner__overlay mobile-show "
                    alt="banner background "
                    placeholder="none"
                />
                <img
                    src={backImg}
                    className="banner__overlay mobile-hide"
                    alt="banner background "
                />

                <div className="banner__data  ">
                    <p className="banner__description banner-description">GET TO KNOW US</p>

                    <div className="c-button">
                        <Link
                            to="/company/"
                            className="c-button__link banner__button"
                        >
                            <img src={arrow} alt="buttonImg" className="c-button__img " />
                            <span className="text-left btn-banner-cta">About us</span>
                        </Link>
                    </div>
                </div>
            </Banner>
            <div>
                <p className="find-us-title">Let’s talk</p>
                <p className="text-center">Send us a message or book a call below.</p>
                <InlineWidget url="https://calendly.com/vecito/30min" styles={{minWidth: 320, height: 780}} />
            </div>
            <SubscribeForm />
            <FindUs />
        </Layout>
    )
}

export default Contact
